import React, {Component} from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import classes from "./Home.module.scss"
// import Countdown from 'react-countdown'
import plungeHero from "../../assets/images/heroPlunge.png"
// import ddHero from "../../assets/images/drawdown24.png"
// import runHero from "../../assets/images/hero2024.png"
import ecfLogo from "../../assets/images/ecfoundation2.png"
import xsLogo from "../../assets/images/xs-logo.png"
import ipLogo from "../../assets/images/ip.png"
import destinHarbor from "../../assets/images/sponsors/lodgeDestinHarbor.png"
import island from "../../assets/images/sponsors/lodgeIslandResort.png"
import ecfLogo2 from "../../assets/images/ecfoundation1.png"
import ecfVideo from "../../assets/images/checkPresentation.mp4"
import prizeVideo from "../../assets/images/grandPrize.mp4"
// import { Message } from "../../components/Shared/Announcement"
// https://www.timestamp-converter.com/

class Home extends Component {
  render() {
    // <Message addClass="show940" />

    return (
      <div className={classes.HomePage}>
        <Helmet>
          <title>Emerald Coast Foundation Presents The Emerald Coast Poker Run</title>
          <meta name="description" content="Boat Week is a week-long boating celebration along the Emerald Coast, that rounds out with The Emerald Coast Poker Run. These events are orchestrated by the Emerald Coast Foundation, an organization that supports children’s charities and youth organizations in Okaloosa County." />
        </Helmet>

        <div className={[classes.simpleHeroX, classes.ddHeroXXX, classes.plungeHero].join(' ')}>
          <a href="/pelican-plunge" className={classes.plhLink}>
            <div className={classes.imageWrap}>
              <img src={plungeHero} alt="Emerald Coast Foundation - Pelican Plunge 2025" className={[classes.heroImage, classes.XXXvidStyle].join(' ')} />

              <h1><span>Click here for</span> <br/>Pelican Plunge Details &amp; Tickets </h1>

              {/* <div className={[classes.content, classes.class3].join(' ')}>
                <div className={[classes.cWrap, classes.med3Def, classes.with3].join(' ')}>
                  <div className={[classes.text, classes.with3].join(' ')}>
                    <h1>2024 Emerald Coast Poker Run</h1>
                    <h2><span>{this.props.dates}</span></h2>
                  </div>

                  <div className={[classes.csClock, classes.runCount, classes.with3].join(' ')}>
                    <Countdown date={'2024-08-09T05:00:00.000Z'} />
                    <div className={classes.csId}>
                      <p className={classes.timeRef}>Days</p>
                      <p className={classes.timeRef}>Hours</p>
                      <p className={classes.timeRef}>Min</p>
                      <p className={classes.timeRef}>Sec</p>
                    </div>
                  </div>

                  <div className={classes.regSide}>
                  <Link to="/register-now" className={[classes.ecLinkRed, classes.ecLinkSm].join(' ')}>Register Now</Link>
                  <div className={classes.clearfix} />
                  <Link to="/poker-run" className={[classes.ecLinkRed, classes.ecLinkSm, classes.link2].join(' ')}>Event Details</Link>
                  </div>
                </div>
              </div> */}
            </div>
          </a>
        </div>

        <div className={classes.HomeBody}>
          <div className={[classes.welcomeBox].join(' ')}>
            <div className={classes.med2Def}>
              <div className={classes.top}>
                <h4>The Emerald Coast Foundation is Proud to Announce Our</h4>
                <h3>Annual Emerald Coast Poker Run<br/> {this.props.dates}</h3>
                <div className={classes.clearfix}/>
                <Link to="/register-now" className={[classes.ecLinkRed, classes.ecLinkSm].join(' ')}>Register Now</Link>
                <Link to="/poker-run" className={[classes.ecLinkRed, classes.ecLinkSm, classes.midLink].join(' ')}>Event Details</Link>
                <Link to="/faqs" className={[classes.ecLinkRed, classes.ecLinkSm].join(' ')}>FAQs</Link>
                {/* <hr className={classes.hlfC} /> */}
              </div>
            </div>
              
            
            <div className={[classes.fullSec, classes.videoBox, classes.runVideo].join(' ')}>
              <div className={classes.medDef}>
                <div className={classes.il1}>
                  <video
                    src={prizeVideo}
                    frameBorder="0"
                    title="Emerald Coast Poker Run Grand Prize"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    data-uk-responsive
                    controls={true}
                    uk-video="automute: true; autoplay: inview;"
                    className={classes.heroVid}
                    loop={true}
                  />
                </div>
                <div className={classes.il2}>
                  <h3>Will <b>YOU</b> Have the Winning Hand?</h3>
                </div>
              </div>
            </div>
            
            <div className={classes.med2Def}>
              <div className={classes.wbWrap}>
                <div className={classes.rtext}>
                  <p><b>Powerboats, fishing boats, pleasure boats,</b> and all in between, will fill the waterways for one of the largest and most popular charity poker runs in the USA!
                  <b> All proceeds from Emerald Coast Boat Week and Poker Run go to the recipient children’s charities of the Emerald Coast Foundation.</b> This event was started over 25 years ago, to give the youth in our local area a chance at a brighter future. </p>
                  <p><b>As of last year, the Emerald Coast Foundation has raised over $2.5 million dollars for our local kids in need.</b> What an impact! This is a volunteer run event. There are no paid employees. Every penny goes to the kids. We do it out of love and dedication to our community and our kids. <b>Please join us for the one and only ORIGINAL Emerald Coast Poker Run!</b> Help us help the kids who need it most.</p>
                </div>
              </div>

              <div className={classes.rls}>
                <img src={ecfLogo} alt="Emerald Coast Foundation logo" className={[classes.imgResponsive, classes.runLogo].join(' ')} />
                <img src={xsLogo} alt="Emerald Coast Poker Run logo" className={[classes.imgResponsive, classes.runLogo].join(' ')} />
                <img src={ipLogo} alt="IP Casino logo" className={[classes.imgResponsive, classes.runLogo].join(' ')} />
              </div>
            </div>
          </div>

          
         
          <div className={[classes.feedComboSpread__XXX, classes.feedLodgeSolo].join(' ')}>
            <div className={classes.medDef}>
              {/* <div className={classes.insta}>
                <p className={classes.h3}>@ec_poker_run</p>
                <script src="https://cdn.lightwidget.com/widgets/lightwidget.js"></script>
                <iframe id="home-feed" title="Emerald Coast Foundation Instagram" src="https://cdn.lightwidget.com/widgets/2da5d2e06f3d5a6d945195f5ef282b41.html" scrolling="no" allowtransparency="true" className={[classes.igPlug, "lightwidget-widget"].join(' ')} style={{width:'100%',border:'0',overflow:'hidden'}} />
              </div> */}

              {/* <div className={classes.lodge}> */}
              <p className={classes.p}>Stay With Our <br/><span>Lodging Partners</span></p>
              <a 
                href="https://www.choicehotels.com/florida/destin/ascend-hotels/flf10?mc=llgoxxpx" 
                target="_blank"
                rel="noreferrer"
                className={[classes.homeLodge, classes.withpXX, classes.one].join(' ')}
              >
                <img src={destinHarbor} alt="Emerald Coast Poker Run Lodging Partner Inn on Destin Harbor" className={[classes.imgResponsive].join(' ')} />
              </a>

              <a 
                href="https://be.synxis.com/?adult=1&arrive=2024-08-08&chain=6255&child=0&currency=USD&depart=2024-08-11&group=2408ECPOKR&hotel=37398&level=hotel&locale=en-US&rooms=1" 
                target="_blank" rel="noreferrer"
                className={[classes.homeLodge, classes.withpXX, classes.two].join(' ')}
              >
                <img src={island} alt="The Island Resort logo"/>
              </a>
              {/* </div> */}
            </div>
          </div>
   
          <div className={classes.foundation}>
            <div className={classes.medDef}>
              <div className={classes.motto}>
                <img src={ecfLogo2} alt="Emerald Coast Foundation Logo" className={[classes.imgResponsive].join(' ')} />
                <p>To provide support, resources, and funding to local children’s charities, in order that they may achieve all their goals.</p>
              </div>

              <div className={classes.ecBody}>
                <div className={classes.tbHalf}>
                  <div className={classes.textBox}>
                    <p className={classes.h3}>A little about us...</p>
                    <p>  
                      The strength of the Emerald Coast Foundation is its ability to achieve its mission: ECF is committed to “Investing in Youth” in Northwest Florida.
                      This capacity is encapsulated in a number of fund raising activities that take place throughout the calendar year. The largest is the Emerald Coast Poker Run, held in August each year.
                      <br/>
                      The Emerald Coast Foundation is the only not-for-profit organization that puts forth such effort, for the sole purpose of expanding its charitable reward and helping our most precious resource: the children of our community. To date, we have raised over 2 million dollars for our causes.
                    </p>
                    <div className={classes.clearfix}/>
                    <Link to="/ec-foundation" className={classes.ecLinkSm}>Learn More</Link>
                  </div>
                </div>

                <div className={[classes.videoBox, classes.ecfVideo].join(' ')}>
                  <video
                    src={ecfVideo}
                    frameBorder="0"
                    title="Emerald Coast Foundation presents donation"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    data-uk-responsive
                    controls={true}
                    uk-video="automute: true; autoplay: inview;"
                    className={classes.FullWidth}
                    loop={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Home